import { Turnstile } from "@marsidev/react-turnstile"
import { useRef, useState } from "react"
import { getUserIp } from "utils/requests/getUserIp"

export type CaptchaInfo = {
  token: string
  ipAddress: string
}

interface CaptchaManagerProps {
  getCaptchaInfo: (result: CaptchaInfo | null) => any
}

export const CaptchaManager = ({ getCaptchaInfo }: CaptchaManagerProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const userIpAddress = useRef<string | null>(null)
  const handleSetCaptchaInfo = async (token: string) => {
    if (!userIpAddress.current) {
      userIpAddress.current = await getUserIp()
    }

    if (token && userIpAddress.current) {
      getCaptchaInfo({
        token,
        ipAddress: userIpAddress.current,
      })
    }
  }

  const handleShowCaptcha = () => {
    setIsVisible(true)
  }

  const handleCaptchaError = error => {
    console.log("Captcha error", error)
    handleShowCaptcha()
  }

  return (
    <div className="flex flex-row flex-1 justify-center">
      <Turnstile
        siteKey={process.env.NEXT_PUBLIC_TURNSTILE_CAPTCHA as string}
        onSuccess={handleSetCaptchaInfo}
        onError={handleCaptchaError}
        onExpire={handleShowCaptcha}
        onUnsupported={handleShowCaptcha}
        onBeforeInteractive={handleShowCaptcha}
        options={{
          size: !isVisible ? "invisible" : "auto",
        }}
      />
    </div>
  )
}
