import { checkUserStatus } from "utils/requests/checkUserStatus"

export const checkUserNeedsPasswordChange = async (email: string) => {
  const userStatus = await checkUserStatus(email)

  if (!userStatus) {
    return false
  }

  return ["FORCE_CHANGE_PASSWORD", "RESET_REQUIRED"].includes(userStatus)
}
