import { Button } from "components/Form/Button"
import { SignedInLottie } from "components/lottie/SignedInLottie/SignedInLottie"
import { useCognitoContext } from "components/Providers/CognitoProvider"
import { useSignInRedirection } from "utils/hooks/useSignInRedirection"

interface SessionConnectedProps {
  handleSignInAgain: () => void
}

export const SessionConnected = ({
  handleSignInAgain,
}: SessionConnectedProps) => {
  const { user } = useCognitoContext()
  const {
    actions: { handleRedirection },
  } = useSignInRedirection()
  const userEmail = user?.get("email")

  return (
    <div className="flex flex-col gap-4">
      <div>
        <h1 className="text-2xl font-bold text-black text-center z-10">
          Continue with
        </h1>
        <h2 className="text-lg font-bold text-black text-center z-10 text-wrap overflow-hidden text-ellipsis">
          {userEmail}?
        </h2>
      </div>
      <div className="cursor-default mt-[-60px] mb-[-60px] z-0">
        <SignedInLottie />
      </div>
      <p className="text-base text-black py-4 text-center z-10">
        You can connect to the game or sign in with another account! Enjoy your
        journey
      </p>
      <div className="flex flex-row justify-between">
        <Button
          label="Switch account"
          onClick={handleSignInAgain}
          variant="secondary"
        />
        <Button label="Continue" onClick={handleRedirection} />
      </div>
    </div>
  )
}
