import Lottie from "react-lottie"
import animationData from "components/lottie/SignedInLottie/signedInAnimation.json"

export const SignedInLottie = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <Lottie
      options={defaultOptions}
      height={280}
      width={280}
      isStopped={false}
      isPaused={false}
      isClickToPauseDisabled={true}
      style={{ cursor: "default" }}
    />
  )
}
