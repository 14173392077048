export const updateABUserProfile = async ({
  accessToken,
  idToken,
}: {
  accessToken: string
  idToken: string
}) => {
  if (!accessToken || !idToken) {
    console.error("No token found")
    throw new Error("No token found")
  }

  const request = await fetch(
    `${process.env.NEXT_PUBLIC_VOXIES_API}/public/v1/users/me/ab-migration`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        idToken,
      }),
    }
  )

  if (!request.ok) {
    throw new Error("Failed to update user profile")
  }
}
