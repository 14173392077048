import { useCognitoContext } from "components/Providers/CognitoProvider"
import { useRouter } from "next/navigation"

export interface SignInRedirectionActions {
  handleRedirection: (defaultRedirect?: boolean) => Promise<void>
  handleDeeplinkRedirection: (refreshToken: string) => void
  handleMarketplaceRedirection: () => void
  handleWebsiteRedirection: () => void
}

export interface SignInRedirectionHook {
  actions: SignInRedirectionActions
}

export function useSignInRedirection(): SignInRedirectionHook {
  const router = useRouter()
  const { getCurrentUser } = useCognitoContext()

  const handleDeeplinkRedirection = (refreshToken: string) => {
    return new Promise<void>(resolve => {
      router.push(`voxietactics://callback?refreshToken=${refreshToken}`)

      const redirectTimout = setTimeout(() => {
        router.push(process.env.NEXT_PUBLIC_GAME_DOWNLOAD_URL as string)
      }, 1000)

      window.addEventListener("blur", () => {
        clearTimeout(redirectTimout)
        resolve()
      })
    })
  }

  const handleMarketplaceRedirection = () => {
    router.push(process.env.NEXT_PUBLIC_MARKETPLACE_URL as string)
  }

  const handleWebsiteRedirection = () => {
    router.push(process.env.NEXT_PUBLIC_WEBSITE_URL as string)
  }

  const handleRedirection = async (defaultRedirect = true) => {
    const { user, session } = await getCurrentUser()

    if (user?.get("custom:walletConnected") !== "1") {
      router.push("/wallet-connect")
      return
    }

    const origin = localStorage.getItem("origin")

    switch (origin) {
      case "game":
        await handleDeeplinkRedirection(session?.getRefreshToken().getToken())
        break
      case "website":
        handleWebsiteRedirection()
        break
      case "marketplace":
        handleMarketplaceRedirection()
        break
      default:
        if (defaultRedirect) {
          router.push("/actions")
        }
        break
    }
  }

  return {
    actions: {
      handleRedirection,
      handleDeeplinkRedirection,
      handleMarketplaceRedirection,
      handleWebsiteRedirection,
    },
  }
}
